import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'

let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

import styled from "@emotion/styled";
const P = styled.p`
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`


class Datenschutz extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <Element id={'header'}/>
        <h1>Datenschutzerklärung</h1>
        <h2>Datenschutz</h2>
        <P>Wir haben diese Datenschutzerklärung (Fassung 16.03.2020-311162591) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln,
          wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</P>
        <br/>
        <P>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen,
          wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</P>
<br/>
        <h2>Cookies</h2>
        <P>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.
          Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</P>

        <br/>
        <h2>Was genau sind Cookies?</h2>
        <P>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge.
          Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies. <br/>

          Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies.
          Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien,
          die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht.
          Ein Cookie besteht aus einem Namen und einem Wert.
          Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
          <br/>
          Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung.
          In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.
          <br/>
          Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“.
          Cookies können auch nicht auf Informationen Ihres PCs zugreifen.
          <br/>
          So können zum Beispiel Cookie-Daten aussehen:
          <br/>

          <ul>
            <li>Name: _ga</li>
            <li>Ablaufzeit: 2 Jahre</li>
            <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
            <li>Beispielhafter Wert: GA1.2.1326744211.152311162591</li>

          </ul>
          <br/>
          <P>Ein Browser sollte folgende Mindestgrößen unterstützen:</P>
          <ul>
            <li>Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
            <li>Pro Domain sollen mindestens 50 Cookies gespeichert werden können</li>
            <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
            <li>Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</li>

          </ul>

        </P>
        <br/>
        <h2>Welche Arten von Cookies gibt es?</h2>
        <P>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt.
          An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</P>


      </Container>
    </Page>
  </IndexLayout>
    )
  }
}

export default Datenschutz
